<template>
  <div :class="['banner', style]">
    <i :class="[iconClass, 'icon-size']"></i>
    <div class="text-container">
      <h3 class="banner-title" v-if="showTitle">{{ title }}</h3>
      <p class="banner-description">{{ description }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Banner",
  props: {
    type: {
      type: String,
      default: "default",
    },
    title: {
      type: String,
      default: "Banner title",
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    description: {
      type: String,
      default: "Banner description",
    },
  },
  computed: {
    iconClass() {
      if (this.type == "success") return "bx bxs-check-circle text-green-dark";
      if (this.type == "warning") return "bx bxs-error-circle text-yellow-dark";
      if (this.type == "critical") return "bx bxs-error text-red-dark";
      if (this.type == "info") return "bx bxs-info-circle text-teal-dark";
      return "bx bxs-info-circle text-blue-base";
    },
    style() {
      if (this.type == "success") return "bg-green-lighter";
      if (this.type == "warning") return "bg-yellow-lighter";
      if (this.type == "critical") return "bg-red-lighter";
      if (this.type == "info") return "bg-teal-lighter";
      return "bg-white border border-grey-base";
    },
  },
};
</script>
<style scoped>
.banner {
  @apply p-4 rounded-md inline-flex;
}

.icon-size {
  @apply text-xl;
}

.text-container {
  @apply ml-4;
}

.banner-title,
.banner-description {
  @apply font-sans text-black-base;
}

.banner-title {
  @apply text-base font-medium;
}

.banner-description {
  @apply text-sm font-normal;
}
</style>