<template>
  <div class="w-full">
    <label v-if="showLabel" class="phone-label" for="value">
      {{ label }}
    </label>
    <div v-if="showDropdown === true" class="input-currency-select-container">
      <div class="input-container">
        <input
            id="value"
            @blur="$emit('blur')"
            @focus="$emit('focus')"
            :class="[showErrMsg ? 'border-red-base' : 'border-grey-dark']"
            type="number"
            :value="value"
            @input="emitValue($event)"
        />
        <span>{{ currency }}</span>
      </div>
      <select
          name="dial-code"
          ref="currency"
          :value="currency"
          @change="emitCurrency($event)"
      >
        <option
            v-for="(currency, index) in currencies"
            :key="index"
            :value="currency"
        >
          {{ currency }}
        </option>
      </select>
    </div>
    <div v-if="showDropdown === false" class="input-currency-select-container select-container-no-dropdown">
      <div class="input-container input-container-no-dropdown">
        <input
            id="value"
            @blur="$emit('blur')"
            @focus="$emit('focus')"
            :class="[showErrMsg ? 'border-red-base' : 'border-grey-dark']"
            type="number"
            :value="value"
            @input="emitValue($event)"
        />
        <span>{{ currency }}</span>
      </div>
    </div>
    <ErrorMessage :errorMsg="errorMsg" v-if="showErrMsg" />
  </div>
</template>
<script>
import ErrorMessage from "@/components/ErrorMessage";
export default {
  name: "InputCurrency",
  components: {
    ErrorMessage
  },
  inheritAttrs: false,
  props: {
    showDropdown: {
      type: Boolean,
      default: true,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Number,
      default: 700,
    },
    errorMsg: {
      type: String,
      default: "Error message",
    },
    showErrMsg: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Label",
    },
    currency: {
      type: String,
      default: "USD",
    },
    currencies: {
      type: Array,
      default: () => ["USD", "EUR", "CHF"],
    },
  },
  data() {
    return {
      currencyList: this.currencies,
    };
  },
  computed: {},
  methods: {
    emitCurrency(event) {
      this.$emit("currency", event.target.value);
    },
    emitValue(event) {
      this.$emit("value", Number(event.target.value))
    }
  },
};
</script>

<style scoped>
input,
select {
  @apply leading-tight w-full p-3 border rounded;
  @apply focus:border-teal-base focus:ring-teal-lighter focus:ring-2;
  @apply text-black-base placeholder-black-lightest;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.input-currency-select-container {
  @apply grid grid-cols-12 gap-3  w-full;
}
.input-currency-select-container .input-container{

}

select,
input {
  @apply border rounded-lg;
}

select {
  @apply border-grey-dark;
}

input {
  @apply pr-20 text-right;
}

.phone-label {
  @apply mb-3 block font-semibold text-black-base;
}

.input-currency-select-container > select{
  @apply col-span-12 xs:col-span-4;
}
.input-container {
  @apply col-span-12 xs:col-span-8 relative;
}
.input-container-no-dropdown{
  @apply xs:col-span-12;
}

.input-container span {
  @apply absolute top-1/2 transform -translate-y-1/2 right-4;
  @apply text-black-lightest border-l border-grey-base;
  @apply w-14 text-right block;
}
</style>