<template>
  <div>
    <nav class="navbar">
      <div class="navbar-wrapper">
        <ButtonIcon @click.native="goToPreviousPage"/>
        <ProgressIndicator
            :currentPage="currentStep"
            :totalPages="totalPages"
        />
      </div>
    </nav>
    <div class="form-page" v-if="isMissingParams">
      <EmptyState
        icon-color="red"
        icon-class="bx bxs-error"
        :use-link="true"
        btn-text="Go to destinations"
        link-path="/destinations"
        title="Something went wrong"
        text="Please try later again."
      />
    </div>
    <form
        action=""
        class="form"
        @submit.prevent="submit"
    >
      <div class="form-page">
        <h2 class="form-page-title" v-t="'page.trip_request.interest.title'"/>

        <Banner
            type="warning"
            :description="`${$t(
            'trip_request.form_1.input_5.banner.description'
          )}`"
            :title="`${$t('trip_request.form_1.input_5.banner.title')}`"
            class="mb-6 w-full"
        />
        <InputCurrency 
          @blur="validateBudget()" 
          @focus="errors.budget.show = false"
          :showErrMsg="errors.budget.show"
          :value="form.budgetValue"
          @value="setBudgetValue"
          @currency="setCurrency"
          :errorMsg="$t(errors.budget.msg) + ` ${budgetCurrency}`"
          :currency="form.budgetCurrency"
          :label="$t('page.trip_request.interest.budget_per_traveller.label')"
          class="mb-8"
        />
        <SelectDropdown
          :label="$t('page.trip_request.interest.reason_for_trip.label')"
          :description="$t('page.trip_request.interest.reason_for_trip.placeholder')"
          :optionsList="tripReasons"
          ref="reason"
          @input="setReason" 
          :show-label="true" 
          class="mb-8"
          :show-err-msg="errors.reason.show"
          :error-msg="$t(errors.reason.msg)"
        />
        <div class="activities-attractions">
          <TextArea
              v-model="form.details"
              :description="$t('trip_request.form_4.input.description')"
              :label="$t('trip_request.form_4.input.label')"
              @change.native="setDetails($event)"
          />
        </div>
        <ButtonPrimary :text="$t('trip_request.button')" class="desktop-cta"/>
      </div>

      <div class="bottom-nav">
      <div class="bottom-nav-wrapper">
        <ButtonPrimary
            :text="`${$t('trip_request.button')}`"
            class="block h-12 px-8 w-full"
        />
      </div>
    </div>
    </form>
  </div>
</template>

<script>
import Banner from "@/components/banners/Banner";
import SelectDropdown from "@/components/dropdowns/SelectDropdown";
import TextArea from "@/components/inputs/TextArea";
import {mapGetters, mapActions} from "vuex";
import ButtonIcon from "@/components/buttons/ButtonIcon";
import InputCurrency from "@/components/inputs/InputCurrency";
import EmptyState from "@/components/EmptyState";
export default {
  name: "Interest",
  components: {
    InputCurrency,
    ButtonIcon,
    Banner,
    TextArea,
    SelectDropdown,
    EmptyState
  },
  data() {
    return {
      isMissingParams: false,
      currencies: [
        {name: "USD", value: "USD"},
        {name: "EUR", value: "EUR"},
        {name: "CHF", value: "CHF"},
        {name: "GBP", value: "GBP"},
      ],
      form: {
        reason: "",
        budgetCurrency: "USD",
        budgetValue: 0,
        details: ""
      },
      errors: {
        budget: {
          msg: "",
          show: false,
        },
        reason: {
          msg: "",
          show: false,
        }
      },
      loading: false,
      tripReasons: [
        {value:"Bachelor party", name:this.$t("page.trip_request.interest.reason_for_trip.option.bachelor")},
        {value:"Birthday", name:this.$t("page.trip_request.interest.reason_for_trip.option.birthday")},
        {value:"Honeymoon", name:this.$t("page.trip_request.interest.reason_for_trip.option.honeymoon")},
        {value:"Retreat", name: this.$t("page.trip_request.interest.reason_for_trip.option.retreat")},
        {value:"Study or school trip", name:this.$t("page.trip_request.interest.reason_for_trip.option.study_trip")},
        {value:"Vacation and recreation", name:this.$t("page.trip_request.interest.reason_for_trip.option.vacation")},
        {value:"Other", name: this.$t("page.trip_request.interest.reason_for_trip.option.other")}
      ],
    };
  },
  methods: {
    ...mapActions({
      increasePage: "request/increasePage",
      decreasePage: "request/decreasePage",
      setTravelReason: "request/setTravelReason",
      setIsAccommodationPage: "request/setIsAccommodationPage",
      setIsContactPage: "request/setIsContactPage",
      setBudgetCurrency: "request/setBudgetCurrency",
      setBudget: "request/setBudget",
      setExtraDetails: "request/setExtraDetails",
      setFormData: "request/setFormData"
    }),
    validateBudget() {
      let check = false;

      if (this.form.budgetValue < 799) {
        this.errors.budget.show = true;
        this.errors.budget.msg = "page.trip_request.interest.budget_per_traveller.error.cant_be_zero";
        check = false;
      } else if (
        this.form.budgetValue === undefined ||
        this.form.budgetValue === null ||
        this.form.budgetValue === "") {
        this.errors.budget.show = true;
        this.errors.budget.msg = "page.trip_request.interest.budget_per_traveller.error.is_required";
        check = false;
      } else {
        this.errors.budget.show = false;
        check = true;
      }
      return check;
    },
    validateReason() {
      let check = false;
      if (this.form.reason === "") {
        this.errors.reason.show = true;
        this.errors.reason.msg = "page.trip_request.interest.reason_for_trip.error";
        check = false;
      } else {
        this.errors.reason.show = false;
        check = true;
      }

      return check;
    },
    setCurrency(currency) {
      this.form.budgetCurrency = currency;
      this.setBudgetCurrency(currency);
    },
    setBudgetValue(value) {
      this.setBudget(value)
      this.form.budgetValue = value;
    },
    validateForm() {
      return [this.validateReason(), this.validateBudget()];
    },
    setReason(reason) {
      this.form.reason = reason;
      this.setTravelReason(reason);
      this.validateReason();
    },
    goToPreviousPage() {
      this.decreasePage();
      if (this.isExperience === true) {
        this.routerPush("/request/experiences");
      } else {
        this.routerPush("/request/traveler-details");
      }
    },
    setDetails(event) {
      this.setExtraDetails(event.target.value);
    },
    routerPush(path) {
      this.$router.push({
            path: path,
            query: {
              q: this.$route.query.q,
              country: this.$route.query.country,
              lang: this.$route.query.lang,
            },
          });
    },
    submit() {
      //Form validation
      if (!this.validateForm().includes(false)) {
        this.increasePage();
        //Go to accommodation if it was selected in the beginning of the form
        if (this.isAccommodation === true) {
          this.setIsAccommodationPage(true);
          this.setFormData(this.form);
          this.routerPush("/request/accommodation");
        } else {
          this.setIsContactPage(true);
          this.setFormData(this.form);
          this.routerPush("/request/contact");
        }
      }
    },
    validateURLParams() {
      let q = this.$route.query.q;
      let country = this.$route.query.country;

      if (q === undefined || country === undefined) {
        this.isMissingParams = true;
      } else {
        this.isMissingParams = false;
      }
    },
  },
  computed: {
    ...mapGetters("request", ["step"]),
    ...mapGetters({
      currentStep: "request/currentStep",
      totalPages: "request/totalPages",
      isExperience: "request/isExperience",
      isAccommodation : "request/isAccommodation",
      travelReason: "request/travelReason",
      isTravelersDetailsPage: "request/isTravelersDetailsPage",
      isTripDetailsPage: "request/isTripDetailsPage",
      isExperiencesPage: "request/isExperiencesPage",
      isInterestPage: "request/isInterestPage",
      budgetCurrency: "request/budgetCurrency",
      budget: "request/budget",
      extraDetails: "request/extraDetails"
    }),
  },
  mounted() {
    //Get date field check dropdown value on page refresh or when landing on page
    let reasonSelect = this.$refs.reason.$el.childNodes[1];
    if (this.travelReason) {
      reasonSelect.value = this.travelReason;
      this.form.reason = this.travelReason;
    }

    //Get budget
    this.form.budgetValue = this.budget;
    
    //Get currency dropdown value on page refresh or when landing on page
    this.form.budgetCurrency = this.budgetCurrency;

    //Get details on page refresh
    this.form.details = this.extraDetails;
  },
  created() {
    this.validateURLParams();
  },
  beforeMount() {
    if (this.isTravelersDetailsPage === false) {
      this.$router.push({
        path: "/request",
        query: {
          q: this.$route.query.q,
          country: this.$route.query.country,
          lang: this.$route.query.lang,
        },
      });
      } else if (this.isTripDetailsPage === false) {
        this.$router.push({
          path: "/request/traveler-details",
          query: {
            q: this.$route.query.q,
            country: this.$route.query.country,
            lang: this.$route.query.lang,
          },
        });
      } else if (
        this.isExperience === false && this.isExperiencesPage === false
      ) {
        this.$router.push({
          path: "/request/traveler-details",
          query: {
            q: this.$route.query.q,
            country: this.$route.query.country,
            lang: this.$route.query.lang,
          },
        });
      } else if (this.isInterestPage === false) {
      //Check if experiences were selected at the beginning of the form
      if (this.isExperience === true) {
        this.$router.push({
          path: "/request/experiences",
          query: {
            q: this.$route.query.q,
            country: this.$route.query.country,
            lang: this.$route.query.lang,
          },
        });
      } else {
        this.$router.push({
          path: "/request/trip-details",
          query: {
            q: this.$route.query.q,
            country: this.$route.query.country,
            lang: this.$route.query.lang,
          },
        });
      }
    }
  },
};
</script>

<style scoped>
.navbar {
  @apply top-0 flex flex-wrap items-center w-full h-20 px-4 bg-white md:px-12;
}

.navbar-wrapper {
  @apply relative flex items-center justify-between w-full h-12 max-w-screen-md transform -translate-x-1/2 left-1/2;
}
.back-btn {
  @apply flex items-center p-3 text-xl duration-300 rounded focus:outline-none focus:ring-2 focus:ring-black-lighter;
  @apply focus:scale-95 focus:ring-offset-2 hover:text-black-base transition ease-in-out text-black-lightest;
}

.form {
  @apply w-full px-4 pb-48 mt-10 md:px-12;
}

.form-page {
  @apply relative max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.form-page-title {
  @apply mb-12 font-sans text-xl font-bold  sm:text-2xl text-black-base;
}

.bottom-nav {
  @apply fixed bottom-0 left-0 w-full p-4 bg-white border-t border-grey-base sm:mt-16;
  @apply block sm:hidden;
}

.bottom-nav-wrapper {
  @apply relative flex items-center justify-between max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.desktop-cta {
  @apply hidden sm:block h-12 px-8 w-full mt-8;
}
</style>
